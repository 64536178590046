import { default as createkJtzwsFN6DMeta } from "/builds/leviia/console/pages/bucket/create.vue?macro=true";
import { default as _91id_93UBjHeFZj20Meta } from "/builds/leviia/console/pages/bucket/edit/[id].vue?macro=true";
import { default as bucketsy22TosoQK0Meta } from "/builds/leviia/console/pages/buckets.vue?macro=true";
import { default as indexFsvxDLM4izMeta } from "/builds/leviia/console/pages/debug/index.vue?macro=true";
import { default as drive_45perso1ozvTZglApMeta } from "/builds/leviia/console/pages/discover/drive-perso.vue?macro=true";
import { default as drive_45proLzqWedMLjCMeta } from "/builds/leviia/console/pages/discover/drive-pro.vue?macro=true";
import { default as nextcloud_45massif28WXofMQf9Meta } from "/builds/leviia/console/pages/discover/nextcloud-massif.vue?macro=true";
import { default as object_45storage4cqZku30frMeta } from "/builds/leviia/console/pages/discover/object-storage.vue?macro=true";
import { default as drive_45pros9HR3EgHsjdMeta } from "/builds/leviia/console/pages/drive-pros.vue?macro=true";
import { default as createCHSHC1C6yeMeta } from "/builds/leviia/console/pages/identifier/create.vue?macro=true";
import { default as _91id_93YFFCA0XsZ9Meta } from "/builds/leviia/console/pages/identifier/edit/[id].vue?macro=true";
import { default as identifiersxcKptsKf4IMeta } from "/builds/leviia/console/pages/identifiers.vue?macro=true";
import { default as indexsE15W18sBBMeta } from "/builds/leviia/console/pages/index.vue?macro=true";
import { default as logoutrA6arH2zjSMeta } from "/builds/leviia/console/pages/logout.vue?macro=true";
import { default as statistiques6kUhg6fn1nMeta } from "/builds/leviia/console/pages/object-storage/statistiques.vue?macro=true";
import { default as index31nUCc3tLkMeta } from "/builds/leviia/console/pages/partners/index.vue?macro=true";
import { default as silent_45check_45ssozs55rXZYH3Meta } from "/builds/leviia/console/pages/silent-check-sso.vue?macro=true";
import { default as bucketsJznDszsXkiMeta } from "/builds/leviia/console/pages/statistiques/details/buckets.vue?macro=true";
import { default as identifiersgmD7vlBCtqMeta } from "/builds/leviia/console/pages/statistiques/details/identifiers.vue?macro=true";
import { default as indexHusQwOI7y8Meta } from "/builds/leviia/console/pages/statistiques/index.vue?macro=true";
import { default as edithYTbMvpYaxMeta } from "/builds/leviia/console/pages/team/member/[id]/edit.vue?macro=true";
import { default as createxKvE2l2lFZMeta } from "/builds/leviia/console/pages/team/member/create.vue?macro=true";
import { default as testetXESqyTkfMeta } from "/builds/leviia/console/pages/test.vue?macro=true";
import { default as detailsx8zWDYbKA0Meta } from "/builds/leviia/console/pages/user/billing/details.vue?macro=true";
import { default as invoicesw2xdQEhVtxMeta } from "/builds/leviia/console/pages/user/billing/invoices.vue?macro=true";
import { default as payment_45methodsRYs1SRdGfKMeta } from "/builds/leviia/console/pages/user/billing/payment-methods.vue?macro=true";
import { default as infosxZr5kbItZIMeta } from "/builds/leviia/console/pages/user/infos.vue?macro=true";
import { default as security4NOa3VfHSBMeta } from "/builds/leviia/console/pages/user/security.vue?macro=true";
import { default as drive_45pro0hXYebJuFpMeta } from "/builds/leviia/console/pages/user/subscriptions/drive-pro.vue?macro=true";
import { default as driveqLLZcUcIb7Meta } from "/builds/leviia/console/pages/user/subscriptions/drive.vue?macro=true";
import { default as indexR9x1vosQvMMeta } from "/builds/leviia/console/pages/user/subscriptions/index.vue?macro=true";
import { default as no_45subxk19PBO5crMeta } from "/builds/leviia/console/pages/user/subscriptions/no-sub.vue?macro=true";
import { default as object_45storagebTSvacDlVXMeta } from "/builds/leviia/console/pages/user/subscriptions/object-storage.vue?macro=true";
import { default as partnerNdIRaDpwHWMeta } from "/builds/leviia/console/pages/user/subscriptions/partner.vue?macro=true";
import { default as teamXP0SNTzMkqMeta } from "/builds/leviia/console/pages/user/team.vue?macro=true";
import { default as identifiersQu24Uh6DPUMeta } from "/builds/leviia/console/pages/v2/identifiers.vue?macro=true";
export default [
  {
    name: "bucket-create",
    path: "/bucket/create",
    meta: createkJtzwsFN6DMeta || {},
    component: () => import("/builds/leviia/console/pages/bucket/create.vue")
  },
  {
    name: "bucket-edit-id",
    path: "/bucket/edit/:id()",
    meta: _91id_93UBjHeFZj20Meta || {},
    component: () => import("/builds/leviia/console/pages/bucket/edit/[id].vue")
  },
  {
    name: "buckets",
    path: "/buckets",
    meta: bucketsy22TosoQK0Meta || {},
    component: () => import("/builds/leviia/console/pages/buckets.vue")
  },
  {
    name: "debug",
    path: "/debug",
    meta: indexFsvxDLM4izMeta || {},
    component: () => import("/builds/leviia/console/pages/debug/index.vue")
  },
  {
    name: "discover-drive-perso",
    path: "/discover/drive-perso",
    meta: drive_45perso1ozvTZglApMeta || {},
    component: () => import("/builds/leviia/console/pages/discover/drive-perso.vue")
  },
  {
    name: "discover-drive-pro",
    path: "/discover/drive-pro",
    meta: drive_45proLzqWedMLjCMeta || {},
    component: () => import("/builds/leviia/console/pages/discover/drive-pro.vue")
  },
  {
    name: "discover-nextcloud-massif",
    path: "/discover/nextcloud-massif",
    component: () => import("/builds/leviia/console/pages/discover/nextcloud-massif.vue")
  },
  {
    name: "discover-object-storage",
    path: "/discover/object-storage",
    component: () => import("/builds/leviia/console/pages/discover/object-storage.vue")
  },
  {
    name: "drive-pros",
    path: "/drive-pros",
    component: () => import("/builds/leviia/console/pages/drive-pros.vue")
  },
  {
    name: "identifier-create",
    path: "/identifier/create",
    meta: createCHSHC1C6yeMeta || {},
    component: () => import("/builds/leviia/console/pages/identifier/create.vue")
  },
  {
    name: "identifier-edit-id",
    path: "/identifier/edit/:id()",
    meta: _91id_93YFFCA0XsZ9Meta || {},
    component: () => import("/builds/leviia/console/pages/identifier/edit/[id].vue")
  },
  {
    name: "identifiers",
    path: "/identifiers",
    meta: identifiersxcKptsKf4IMeta || {},
    component: () => import("/builds/leviia/console/pages/identifiers.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/leviia/console/pages/index.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/builds/leviia/console/pages/logout.vue")
  },
  {
    name: "object-storage-statistiques",
    path: "/object-storage/statistiques",
    component: () => import("/builds/leviia/console/pages/object-storage/statistiques.vue")
  },
  {
    name: "partners",
    path: "/partners",
    meta: index31nUCc3tLkMeta || {},
    component: () => import("/builds/leviia/console/pages/partners/index.vue")
  },
  {
    name: "silent-check-sso",
    path: "/silent-check-sso",
    component: () => import("/builds/leviia/console/pages/silent-check-sso.vue")
  },
  {
    name: "statistiques-details-buckets",
    path: "/statistiques/details/buckets",
    component: () => import("/builds/leviia/console/pages/statistiques/details/buckets.vue")
  },
  {
    name: "statistiques-details-identifiers",
    path: "/statistiques/details/identifiers",
    component: () => import("/builds/leviia/console/pages/statistiques/details/identifiers.vue")
  },
  {
    name: "statistiques",
    path: "/statistiques",
    component: () => import("/builds/leviia/console/pages/statistiques/index.vue")
  },
  {
    name: "team-member-id-edit",
    path: "/team/member/:id()/edit",
    component: () => import("/builds/leviia/console/pages/team/member/[id]/edit.vue")
  },
  {
    name: "team-member-create",
    path: "/team/member/create",
    component: () => import("/builds/leviia/console/pages/team/member/create.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/builds/leviia/console/pages/test.vue")
  },
  {
    name: "user-billing-details",
    path: "/user/billing/details",
    component: () => import("/builds/leviia/console/pages/user/billing/details.vue")
  },
  {
    name: "user-billing-invoices",
    path: "/user/billing/invoices",
    meta: invoicesw2xdQEhVtxMeta || {},
    component: () => import("/builds/leviia/console/pages/user/billing/invoices.vue")
  },
  {
    name: "user-billing-payment-methods",
    path: "/user/billing/payment-methods",
    meta: payment_45methodsRYs1SRdGfKMeta || {},
    component: () => import("/builds/leviia/console/pages/user/billing/payment-methods.vue")
  },
  {
    name: "user-infos",
    path: "/user/infos",
    component: () => import("/builds/leviia/console/pages/user/infos.vue")
  },
  {
    name: "user-security",
    path: "/user/security",
    component: () => import("/builds/leviia/console/pages/user/security.vue")
  },
  {
    name: "user-subscriptions-drive-pro",
    path: "/user/subscriptions/drive-pro",
    meta: drive_45pro0hXYebJuFpMeta || {},
    component: () => import("/builds/leviia/console/pages/user/subscriptions/drive-pro.vue")
  },
  {
    name: "user-subscriptions-drive",
    path: "/user/subscriptions/drive",
    meta: driveqLLZcUcIb7Meta || {},
    component: () => import("/builds/leviia/console/pages/user/subscriptions/drive.vue")
  },
  {
    name: "user-subscriptions",
    path: "/user/subscriptions",
    component: () => import("/builds/leviia/console/pages/user/subscriptions/index.vue")
  },
  {
    name: "user-subscriptions-no-sub",
    path: "/user/subscriptions/no-sub",
    component: () => import("/builds/leviia/console/pages/user/subscriptions/no-sub.vue")
  },
  {
    name: "user-subscriptions-object-storage",
    path: "/user/subscriptions/object-storage",
    meta: object_45storagebTSvacDlVXMeta || {},
    component: () => import("/builds/leviia/console/pages/user/subscriptions/object-storage.vue")
  },
  {
    name: "user-subscriptions-partner",
    path: "/user/subscriptions/partner",
    meta: partnerNdIRaDpwHWMeta || {},
    component: () => import("/builds/leviia/console/pages/user/subscriptions/partner.vue")
  },
  {
    name: "user-team",
    path: "/user/team",
    meta: teamXP0SNTzMkqMeta || {},
    component: () => import("/builds/leviia/console/pages/user/team.vue")
  },
  {
    name: "v2-identifiers",
    path: "/v2/identifiers",
    component: () => import("/builds/leviia/console/pages/v2/identifiers.vue")
  }
]