import { createI18n } from "vue-i18n";

import en from "../translations/en.json";
import fr from "../translations/fr.json";
import jp from "../translations/jp.json";

export type MessageSchema = typeof fr;

// Type auxiliaire générique pour gérer la récursivité
type TradKeysHelper<T, P extends string = ""> = {
    [K in keyof T]: T[K] extends object
        ? TradKeysHelper<T[K], P extends "" ? K & string : `${P}.${K & string}`>
        : P extends "" ? K & string : `${P}.${K & string}`
}[keyof T];

export type TradKey = TradKeysHelper<MessageSchema>;

export const allowedLanguages = [
    "en",
    "fr",
    "jp"
];
export const defaultLanguage = "fr";

function getLanguageFromNavigator (): string | null {
    const language = navigator.language.split("-")[0];
    if (allowedLanguages.includes(language)) {
        return language;
    }
    return null;
}

function getLanguageFromLocalStorage (): string | null {
    const language = localStorage.getItem("locale");
    if (language && allowedLanguages.includes(language)) {
        return language;
    }
    return null;
}

export function getLanguage (): string {
    return getLanguageFromLocalStorage() || getLanguageFromNavigator() || defaultLanguage;
}

export default defineNuxtPlugin(({ vueApp }) => {
    const i18n = createI18n<[MessageSchema], "fr" | "en" | "jp">({
        legacy: true,
        locale: getLanguage(),
        fallbackLocale: defaultLanguage,
        messages: {
            fr,
            en,
            jp
        }
    });

    vueApp.use(i18n);
});
